.description {
    font-size: 1rem;
    max-width: 800px; /* Optional: limit the width for better readability */
    margin: 0 auto; /* Center align horizontally */
    flex-direction: column;
    text-align: center;
}

@media (max-width: 600px) {
.app .about {
    align-items: flex-start;
    margin-top: 2em;
}
}