.project-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    white-space: pre-wrap;
  }
  
  .project-section.flipped {
    flex-direction: row-reverse;
  }
  
  .project-text {
    flex: 1;
  }
  
  .project-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .project-text p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .project-image {
    flex: 1;
    max-width: 400px;
  }
  
  .project-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .project-title {
  margin-bottom: 0.75em;
  text-transform: uppercase;
  }