.skills__list {
  max-width: 700px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
  position: relative;
}

.skills__list-item:hover{
  transform: scale(1.1);
  z-index: 1;
}
  
.skill-description {
  display: none;
  position:absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--clr-fg-alt);
  color: var(--clr-bg-alt);
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9em;
  z-index: 100;
  max-width: 200px; /* Set a max-width to limit the width of the description box */
  width:max-content; /* Ensures the description box is always 300px wide */
  word-wrap: break-word; /* Ensures long words break to fit within the box */
  white-space: normal; /* Allows the text to wrap */
  text-align: center; /* Centers the text within the box */
}

.skills__list-item:hover .skill-description {
  display: block;
}

.skills__introduction {
  font-size: 1rem;
  max-width: 750px; /* Optional: limit the width for better readability */
  margin: 0 auto; /* Center align horizontally */
  flex-direction: column;
  margin-bottom: 1em;
  text-align: center;
}
