.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__list-item {
  margin-right: 2em; /* Adds spacing between title and Personal Projects link */
  font-weight: 500;
}

.header__projects-link {
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
}

@media (max-width: 600px) {
  .header {
    height: 6em;
    flex-direction: column;
    align-items: flex-start;
  }

  .header__list {
    flex-direction: column;
  }

  .header__list-item {
    margin-bottom: 1em; /* Adds space between title and link on small screens */
  }
}

.header h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
