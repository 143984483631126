.project-header {
  display: flex;
  align-items: center;  /* Align the items (title and logo) in the center vertically */
}

.project-title {
  margin-right: 1em;  /* Adjust the space between the title and the logo */
}

.github-link {
  display: flex;
  align-items: center;
}

.github-icon {
  font-size: 1.5em;  /* Adjust the size of the GitHub icon */
  color: #333;       /* Optional: Change the color of the GitHub icon */
}

.project-description {
  white-space: pre-wrap;
}