.about {
    flex-direction: column;
    margin-top: 0.3em;
  }
  
  .about__name {
    color: var(--clr-primary);
  }
  
  .about__role {
    margin-top: 1.2em;
  }
  
  .about__desc {
    font-size: 1rem;
    max-width: 600px;
    text-align: center;
  }
  
  .about__desc,
  .about__contact {
    margin-top: 2.4em;
  }
  
  .about .link--icon {
    margin-right: 0.8em;
  }
  
  .about .btn--outline {
    margin-bottom: 1em;
    margin-right: 1em;
  }
  
  @media (max-width: 600px) {
    .app .about {
      align-items: flex-start;
      margin-top: 2em;
    }
  }
  
  .about__image {
    width: 225px; /* Adjust the size as needed */
    height: 225px; /* Adjust the size as needed */
    border-radius: 50%; /* Makes the image circular */
    margin-bottom: 1.5em; /* Adds space below the image */
    object-fit: cover; /* Ensures the image fits within the circle */
    border: 2px solid var(--clr-primary); /* Adjust the color and width as needed */
    padding: 5px; /* Optional: Add padding inside the border */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for depth */
  }
  