.introduction {
    flex-direction: column;
    margin-top: 3em;
}

.experience__introduction {
    font-size: 1rem;
    max-width: 800px; /* Optional: limit the width for better readability */
    margin: 0 auto; /* Center align horizontally */
    flex-direction: column;
    margin-bottom: 1em;
  }

.experiences__grid {
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(18em, 1fr)); */
    grid-gap: 2em;
}

@media (max-width: 600px) {
.app .about {
    align-items: flex-start;
    margin-top: 2em;
}
}